/*Planning*/

.popupContainer {
    display: flex;
    flex-direction: row;
}

.popupText {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    place-content: space-between;
}

.popupComposants {
    margin: 3vh 0vw 3vh 0vw;
}

.popupAvatar {
    margin-left: 3vw;
    height: 22vh;
    width: 11vw;
}

.noStyleLink {
    text-decoration: none;
    color: inherit;
}

.timelineControl {
    display: flex;
    justify-content: space-between;
    margin: 1vw;
}

.planningContainer {
    flex: 1; 
    display: flex;
    flex-direction: column; 
    border: 1px solid #D6DCE2;
    margin: 2vh 2.5vw 2.5vh 2.5vw;
    border-radius: 8px;
}

/*Legend*/

.legendContainer {
    display: flex;
    margin-top: 1vh; 
}

.legendItems {
    margin: 1vh;
}

.legendTitle {
    border-top: solid 1px #D6DCE2;
    padding: 1vh 0vw 0vh 1vh;
}
/*SearchPannel*/

.ui.accordion.fiche-panel-header.planningSearchAccordion.segment {
    margin: 5vh 2.5vw 2.5vh 2.5vw !important;

    .title {
        padding: 0;

        .accordionTitleContainer {
            display: inline-flex;
        }
    }
}