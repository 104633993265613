@import '@jvs-group/jvs-mairistem-styles/brands';

#root {
    display: flex;
    flex-direction: column;
}

/*Progress bar*/
.ui.progress.with-steps .left {
    text-align: right;
    padding-right: 0.4vw;
}

.bold {
    font-weight: bold;
}

.ui.progress.with-steps .right {
    z-index: 50;
}

.ui.progress.with-steps {
    display: flex;
    border-radius: 0;
    min-height: 23px;
    cursor: pointer;
}

.ui.progress.with-steps .segment {
    padding: 0;
    margin-bottom: 0;
}

.ui.progress.with-steps:first-child {
    margin-bottom: 0;
}

.ui.message {
    min-height: auto !important;
}

.wrapper {
    position: relative;
    margin-bottom: 20px;
}

.step {
    position: absolute;
    width: 0;
    border: dashed white 1px;
    height: 100%;
    top: 0;
    z-index: 10;
}

.step.darkened {
    border-color: #b6b7b8;
}

.ui.nopadding {
    padding: 0;
}

.progress.with-steps .right {
    padding-left: 0.4vw;
}

.step:after {
    content: attr(data-step);
    position: absolute;
    top: 105%;
    width: 50px;
    text-align: center;
    right: -25px;
}

.progress.with-steps.total:after {
    content: attr(data-total);
    position: absolute;
    bottom: 105%;
    width: 50px;
    text-align: center;
    right: -25px;
}

.visibility {
    margin-bottom: 10px;
}

#tableEcritures {
    margin-top: 30px;
}

.circular.progressbar.value {
    transform: rotate(-90deg);
    transform-origin: center;
}

.value {
    font-weight: 700;
}

.full.height {
    height: 100%;
}

.avatar {
    height: 100%;
    background-position: center;
    background-size: cover;
    flex-direction: row; 
}

.placeloaderAvatarPopup {
    height: 100%;
    flex-direction: row;
}

.ui.button.avatar-menu {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: white;
}

.hidden {
    display: none;
}

.flex {
    display: flex;
}

.vflex {
    display: flex !important;
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.ui.grid>.row>.column.inline.flex,
.inline.flex {
    display: inline-flex;
}

.ui.form .field.percent.small {
    width: 80px;
    margin-left: 20px;
}

#versementsTable {
    cursor: pointer;
}

.avatarWrapper {
    position: relative;
    height: 100%;
}

.avatarWrapper .with-margin {
    margin-top: 2vh;
}

input::-webkit-calendar-picker-indicator {
    display: none !important;
}

.ui.liste-table>.loader {
    display: none !important;
}

/* Etiquettes */
.etiquetteInput {
    margin-bottom: 1em;
}
.legacy .delete.icon {
    display: none;
}

.toggleEtat {
    margin-bottom: 10px;
}

.etatSubvention {
    display: flex;
    justify-content: end;
}

/*Composants*/
.composants .liste-table-container {
    padding-bottom: 1em;
    .currencyInput {
        text-align: right;
    }
}

.collectionlist .column {
    color: black;
}

/*Charts*/
.echChart {
    width: 15vw !important;
    height: 15vw !important;
    align-self: center;
}

.dashboard {
    overflow: auto;
}

.financeur {
    margin-bottom: 2vh;
}

/* Icon etat condition de versement */
.ui.grid {
    .etatRow{
        align-items: center ;
        gap: 2vw;
        flex-wrap: nowrap;
        span {
            display: flex;
            gap: 0.7vw;
        }
    }  
}

.ui.table{
    .reglesTableau{
        display: flex;
        justify-content: space-between;
        .etatsIconsTableau {
            display: flex;
            flex-direction: column;
        }
    }
}

.etatIcons{
    border-radius: 50%;
    padding: 0.2vw;
}

/* SubventionListe & ProjetListe */
.cloturedProjetRow {
    color: rgb(151, 146, 146);
}

//TODO: a voir pour corriger dans liste
.projetSubventionListe .liste-header {
    display: flex;
    flex-direction: column;
    //flex-direction: column-reverse;

    // fix bug d'affichage header liste
    .ui.form .fields:not(.grouped):not(.inline) .field:not(:only-child) .ui.checkbox {
        padding-top: 0;
        margin-top: 0;
    }

    .ui.secondary.menu .item {
        padding: 0;
    }

    .liste-header-menu {
        min-height: 0;
    }

    // options rajoutées au header de liste 
    .liste-header-options .item {
        width: 100%;
        form {
            width: 100%;
            .fields {
                .field {
                    width: 100%;
                    max-width: 45vw;
                }

                // checkboxes de filtre sur le header des listes
                .filterCheckboxed {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 3vw;
                }

                align-items: center;
                gap: 1vw;
            }
        }
    }

    // champ de recherche liste
    .liste-header-search {
        max-width: 40vw;
    }
}