.financement-table {
    align-self: center;
    justify-content: end;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.legend {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10%;
}

.autofinancement {
    background-color: #ED7D31;
}

.financement_externes {
    background-color: #5B9BD5;
}

.flex {
    display: flex !important;
}

.pointer {
    cursor: pointer;
}

#modeRealisationFinancement {
    display: flex;
    justify-content: end;
}