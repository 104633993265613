.table .menu .icon.button, .table .menu .icon.button:hover, .table .menu .icon.button:focus {
    background: 0 0;
    border: none;
    color: #109fdb;
    text-align: center;
}

.ui.table tr td.menu, .ui.table tr th.menu {
    border-left: none;
}

@media (min-width:992px) {
    .ui.table .menu button{
        visibility: hidden;
    }

    .ui.table tr:hover .menu button{
        visibility: visible;
    }
}