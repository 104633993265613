#parametres{
    margin: 3vh;
    height: calc(100vh - 36px);
    
    .leviiaButton {
        display: flex;
        align-items: center;
        gap: 5vw;
    }
}

.gabarit {
    height: 100%;
}


@media only screen and (max-width: 767px) {
    #parametres .row{
        flex-direction: column;
    }

    #parametres #menu-content {
        flex: 1;
        padding-top: 3vh;
    }
}